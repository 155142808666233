class PinInput {
  constructor(onComplete) {
    // Create parent container
    this.parentContainer = document.createElement('div');
    this.parentContainer.setAttribute('id', 'pin-input-container');
    this.parentContainer.classList.add('pin-input-container');

    this.inputFieldContainer = document.createElement('div');
    this.inputFieldContainer.classList.add('pin-input');

    this.inputFields = [];
    this.authenticated = false; // Flag to track authentication status

    this.errorMessage = document.createElement('p');
    this.errorMessage.setAttribute('id', 'error-msg');
    this.errorMessage.classList.add('pin-input');

    for (let i = 0; i < 6; i++) {
      const inputField = document.createElement('input');
      inputField.type = 'text';
      inputField.maxLength = 1;
      inputField.classList.add('pin-field');
      inputField.addEventListener('keypress', this.handleKeyPress.bind(this));
      inputField.addEventListener('input', this.handleInput.bind(this, i));
      inputField.addEventListener('keydown', this.handleBackspace.bind(this, i));
      this.inputFields.push(inputField);
      this.inputFieldContainer.appendChild(inputField);
    }

    // Create loading animation elements
    this.loadingDotsContainer = document.createElement('div');
    this.loadingDotsContainer.classList.add('loading-dots');
    this.loadingDots = [];
    for (let i = 0; i < 3; i++) {
      const loadingDot = document.createElement('div');
      loadingDot.classList.add('loading-dot');
      this.loadingDots.push(loadingDot);
      this.loadingDotsContainer.appendChild(loadingDot);
    }

    this.parentContainer.appendChild(this.inputFieldContainer);
    this.parentContainer.appendChild(this.errorMessage);

    this.onComplete = onComplete;
  }

  handleKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  handleInput(index, event) {
    const input = event.target;
    if (input.value.length === 1 && index < this.inputFields.length - 1) {
      this.inputFields[index + 1].focus();
    }
    if (index === this.inputFields.length - 1 && this.isFilled() && !this.authenticated) {
      this.freezeInputs();
      this.onComplete(this.inputFields.map(input => input.value).join(''));
    }
  }

  handleBackspace(index, event) {
    if (event.key === 'Backspace' && index > 0 && this.inputFields[index].value === '') {
      this.inputFields[index - 1].focus();
      this.inputFields[index - 1].value = '';
    }
  }

  isFilled() {
    return this.inputFields.every(input => input.value.length === 1);
  }

  freezeInputs() {
    this.inputFields.forEach(input => {
      input.disabled = true;
    });
    // Show loading animation
    this.parentContainer.appendChild(this.loadingDotsContainer);
  }

  unfreezeInputs() {
    this.inputFields.forEach(input => {
      input.disabled = false;
      input.value = "";
    });
    this.inputFields[0].focus();
    // Hide loading animation
    this.parentContainer.removeChild(this.loadingDotsContainer);
  }

  render() {
    return this.parentContainer;
  }
}

export default PinInput;
