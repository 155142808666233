import firebaseInstance from './firebaseInit';
import FirebaseAuth from './firebaseAuth';
import WebXRScene from './webXRScene';
import PinInput from './components/PinInput';
import './styles/main.css';

const firebaseAuth = new FirebaseAuth(firebaseInstance.app);
const errorMsgContents = "Invalid PIN entered";
const webxrContainer = 'web-xr-content';
const loginContainer = 'pin-input-container';
var sceneInstance = null;

//testPeardropAirQual();
//testPeardropFootfall();
testPeardropTileLoader();

const onPinInputComplete = (pinValue) => {
    firebaseAuth.signIn('user@test.com', pinValue)
        .then((userCredential) => {
            // Hide login and show WebXR content
            document.getElementById(loginContainer).style.display = 'none';
            document.getElementById(webxrContainer).style.display = 'block';
            initializeWebXR(); // Function to initialize WebXR content
        })
        .catch((error) => {
            document.getElementById('error-msg').textContent = errorMsgContents;
            setTimeout(() => {
                pinInput.unfreezeInputs();
              }, 2000);
        });
  };

const pinInput = new PinInput(onPinInputComplete);
document.body.appendChild(pinInput.render());

// Check auth state on page load
firebaseAuth.onAuthStateChanged(user => {
    if (user) {
        console.log('User is signed in:', user);
        document.getElementById(loginContainer).style.display = 'none';
        document.getElementById(webxrContainer).style.display = 'block';
        initializeWebXR(); // Function to initialize WebXR content
    } else {
        console.log('No user is signed in.');
        document.getElementById(loginContainer).style.display = 'block';
        document.getElementById(webxrContainer).style.display = 'none';
    }
});

function initializeWebXR() {
    if (sceneInstance === null)
    {
        sceneInstance = new WebXRScene(webxrContainer);
    }
}

function testPeardropAirQual() {
    const region = {
        latitude: 37.419734, 
        longitude: -122.0827784, 
        distance: 1000, 
        resolution: 400
    };

    fetch('http://localhost:3000/sampleAirQuality', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ region })
        })
        .then(response => response.json())
        .then(data => {
        if (data.success) {
            console.log('Air quality data:', data.data);
            // Process and display the sampled data in your frontend UI
        } else {
            console.error('Error:', data.error);
        }
        })
        .catch(error => {
        console.error('Error:', error);
    });
}

function testPeardropFootfall() {
    const region = {
        query: 'bars, cafes and restaurants',
        number: 20,
        latitude: 37.419734, 
        longitude: -122.0827784, 
        distance: 1000,
    };

    fetch('http://localhost:3000/sampleFootfall', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ region })
        })
        .then(response => response.json())
        .then(data => {
        if (data.success) {
            console.log('Footfall data:', data.data);
            // Process and display the sampled data in your frontend UI
        } else {
            console.error('Error:', data.error);
        }
        })
        .catch(error => {
        console.error('Error:', error);
    });
}

async function testPeardropTileLoader() {
    const region = {
        latitude: 37.419734, 
        longitude: -122.0827784, 
        distance: 20, 
        resolution: 2
    };

    const queryString = new URLSearchParams(region).toString();

    try {
        const response = await fetch(`http://localhost:3000/process-region?${queryString}`);
        const data = await response.json();
        console.log('Data received:', data);
    
        const modelStream = `http://localhost:3000/stream-file/${data.keys[0]}`;
    
        // Trigger load in webXR scene
        sceneInstance.loadglb(sceneInstance.scene, modelStream);
    } catch (error) {
        console.error('Error:', error);
    }
}