// Import Firebase modules
import { initializeApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

class FirebaseApp {
    constructor() {
        // Firebase configuration
        this.firebaseConfig = {
            apiKey: "AIzaSyDmJ207Qlf3vj_NK8MZXbukP4aczGdK3_M",
            authDomain: "pwc-webxr.firebaseapp.com",
            projectId: "pwc-webxr",
            storageBucket: "pwc-webxr.appspot.com",
            messagingSenderId: "572974491309",
            appId: "1:572974491309:web:ab9c2100a0159f26328824",
            measurementId: "G-DHT6P3C2HX"
          };

        // Initialize Firebase
        this.app = initializeApp(this.firebaseConfig);

        if (process.env.NODE_ENV === 'development') {
            connectStorageEmulator(this.storage(), 'localhost', 9199);
        }
    }

    getDownloadURL(pathToFile) {
        const ref = this.storageRef(pathToFile);
        return getDownloadURL(ref);  // Return the promise directly
    }

    storageRef(pathToFile) {
        return ref(this.storage(), pathToFile);
    }

    storage() {
        // Ensure this.app is initialized with your Firebase app instance
        return getStorage(this.app);
    }

    // Add other getters for different Firebase services, e.g., auth, firestore, etc.
}

// Export an instance of the class
const firebaseInstance = new FirebaseApp();
export default firebaseInstance;
