import { getAuth, connectAuthEmulator, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';

class FirebaseAuth {
    constructor(app) {
        this.app = app;
        this.auth = getAuth(this.app);
		/*
        if (window.location.hostname === "localhost") {
            connectAuthEmulator(this.auth, "http://127.0.0.1:9099")
        }*/
    }
	

    signIn(email, password) {
        return signInWithEmailAndPassword(this.auth, email, password);
    }

    onAuthStateChanged(callback) {
        onAuthStateChanged(this.auth, callback);
    }
}

export default FirebaseAuth;