// LoadingBar.js
import * as THREE from 'three';
import FontJSON from './assets/Roboto-msdf.json';
import FontImage from './assets/Roboto-msdf.png';
import ThreeMeshUI from 'three-mesh-ui';
export default class LoadingBar {
    constructor(scene,text = 'LOADING') {
        this.scene = scene;
        this.group = new THREE.Group();
        this.scene.add(this.group);
        this.group.position.set(0, 1.45, -1.15);

        this.textContent=null;
        this.text = text;
        this.dots = '';
        this.maxDots = 3;

        this.StartAnim=0;

        this.DrawText();

    }
    DrawText() {
        this.textContent = new ThreeMeshUI.Text({ content: `${this.text}${this.dots}` });
        var _textWidth = .5;
        var _textHeight = 0.01;
        var _fontSize = 0.045;
        var _textfontColor = new THREE.Color(0x000000);
        var _textPos = new THREE.Vector3(0, 0.15, 0);

        const TextBlock = new ThreeMeshUI.Block({
            fontFamily: FontJSON,
            fontTexture: FontImage,
            width: _textWidth,
            height: _textHeight,
            fontSize: _fontSize,
            backgroundOpacity: 1,
            backgroundColor:new THREE.Color(0xFFFFFF),
            borderColor:new THREE.Color(0xFFFFFF),
            fontColor: _textfontColor,
            justifyContent: 'center',
            textAlign: 'center'
        }).add(this.textContent);

        TextBlock.position.set(_textPos.x, _textPos.y, _textPos.z);
        this.group.add(TextBlock);
    }

    async animateDots() {
        while (this.StartAnim==1) {
            this.dots = this.dots.length < this.maxDots ? this.dots + '.' : '';
            this.textContent.set({ content: `${this.text}${this.dots}` });
            await new Promise(resolve => setTimeout(resolve, 200));
        }
    }

    show() {
        this.group.visible = true;
        this.StartAnim=1;
        this.animateDots(); // Call the async function
    }

    hide() {
        this.group.visible = false;
        this.StartAnim=0;
    }
}